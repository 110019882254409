import { makeStyles } from '@mui/styles';
import classNames from 'clsx';
import Container from 'components/Container/Container';
import LoginIcon from '@mui/icons-material/Login';
import AccountIcon from '@mui/icons-material/AccountCircle';
// import LogoSymbol from 'components/Img/logo-1.png';
// import LogoSymbolWhite from 'components/Img/bpswhite2.png';
import LogoSymbolWhite from 'components/Img/bpswhite.png';
import PropTypes from 'prop-types';
import { cloneElement, memo, useRef, useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import qs from 'qs';
// import ExpandMore from '@mui/icons-material/ExpandMore';
// import ReactCountryFlag from 'react-country-flag';
import { useAppSelector } from 'hooks/redux';

import {
  AppBar,
  Button,
  CircularProgress,
  Toolbar,
  Typography,
  useScrollTrigger,
  Menu,
  MenuItem,
  Alert,
} from '@mui/material';
import { green } from '@mui/material/colors';

function ElevationScroll(props: any) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const delta = 10;

const getDocScroll = () => {
  const doc = document.documentElement;
  const left = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
  const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
  return { top, left };
};

// const langsToIsoCode = {
//   en: 'US',
//   es: 'ES',
//   pt: 'PT',
// };
// const langsToText = {
//   en: 'English',
//   es: 'Spanish',
//   pt: 'Portuguese',
// };

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
    },
    grow: {
      flex: '1 1 auto',
    },
    logoHeader: {
      marginTop: -20,
      width: 145,
      paddingLeft: 5,
      paddingRight: 5,
      borderRadius: 5,
      fill: '#ffffff',
      backgroundColor: 'transparent',
    },
    headerRoot: {
      // background: '#fff',
      background: 'rgba(255,255,255,.97)',
      border: 0,
      boxShadow: 'none',
    },
    headerWrap: {
      color: 'rgba(0,0,0,.54)',
      fontSize: 16,
      fontFamily: [
        'wm-content-sans-serif-font',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        '"Open Sans"',
        '"Helvetica Neue"',
        'sans-serif',
      ].join(','),
      '-webkit-transition': '-webkit-transform .3s',
      transition: 'transform .3s',
      fallbacks: [
        { transition: '-webkit-transform .3s' },
        { transition: 'transform .3s, -webkit-transform .3s' },
      ],
      '-webkit-transform': 'translateY(0%)',
      transform: 'translateY(0%)',
    },
    headerBorder: {
      '-webkit-box-shadow': '0 2px 2px -2px rgba(0,0,0,.15)',
      boxShadow: '0 2px 2px -2px rgba(0,0,0,.15)',
    },
    toolbar: {
      marginTop: '20px',
      padding: 0,
      width: '100%',
    },
    toolbarYellow: {
      backgroundColor: '#fce900',
      '& $toolbar': {
        minHeight: 38,
        height: 38,
      },
    },
    toolbarTransparent: {
      position: 'relative',
      backgroundColor: 'transparent',
    },
    navUp: {
      '-webkit-transform': 'translateY(-100%)',
      transform: 'translateY(-100%)',
    },
    navDown: {},
    containerAfterOverlay: {
      position: 'relative',
      zIndex: 100,
    },
    separatorMainLogoContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    separatorMainLogo: {
      borderRight: '1px solid rgba(0,0,0,.15)',
      marginLeft: 15,
      marginRight: 20,
      height: 28,
      display: 'inline-block',
    },
    wrapperIsOnline: {
      position: 'fixed',
      overflow: 'visible',
      zIndex: 9999,
      top: 0,
      left: 0,
      right: 0,
      height: 50,
      lineHeight: '50px',
      '& ~ *': {
        transform: 'translateY(+45px)',
      },
    },
    containerIsOnline: {
      position: 'fixed',
      backgroundColor: '#ffa000',
      borderBottom: '1px solid red',
      color: '#fff',
      top: 0,
      left: 0,
      right: 0,
      height: 50,
      lineHeight: '50px',
      textAlign: 'center',
    },
    buttonDownConn: {
      position: 'relative',
      top: 50,
    },
    wrapperConn: {
      textAlign: 'center',
    },
    buttonProgress: {
      position: 'absolute',
      color: green[500],
      top: '50%',
      left: '50%',
      marginTop: 38,
      marginLeft: -12,
    },
    popper: {
      position: 'static',
      zIndex: 9999,
    },
    menuButton: {
      whiteSpace: 'nowrap',
      color: 'rgba(255,255,255,.75)',
      '&:hover': {
        color: 'rgba(255,255,255,1)',
      },
    },
    menuButtonBlack: {
      whiteSpace: 'nowrap',
      color: 'rgba(0,0,0,.75)',
      '&:hover': {
        color: 'rgba(0,0,0,1)',
      },
    },
    menuPaper: {},
    menuSectionWrapper: {
      borderWidth: 1,
      borderLeftStyle: 'solid',
      borderColor: '#f7f7f7',
    },
    menuItemSection: {
      color: '#000',
      opacity: 1,
      fontSize: 15,
      minHeight: 18,
      padding: 0,
      paddingLeft: 16,
      paddingRight: 16,
      paddingBottom: 8,
      fontWeight: 'bold',
      '&.disabled': {
        opacity: 1,
      },
    },
    menuItem: {
      minHeight: 18,
      fontSize: 12,
      textTransform: 'uppercase',
      padding: 0,
      paddingLeft: 16,
      paddingRight: 16,
    },
    langMenu: {
      position: 'absolute',
      zIndex: 99,
      background: '#fff',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    containerLangs: {
      height: '100%',
    },
    containerLangsGrid: {
      height: '100%',
    },
    menuLang: {
      marginTop: 10,
    },
    textToolBarYellow: {
      fontSize: 12,
    },
    iconFlag: {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacing' does not exist on type 'Default... Remove this comment to see the full error message
      marginRight: theme.spacing(1),
    },
    searchButton: {
      color: '#fff',
    },
    boxSearch: {
      width: 0,
      transition: 'width 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    boxSearchOpen: {
      width: 300,
    },
    leftDivSearch: {
      transition: 'all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    leftDivSearchOpen: {
      transform: 'translateX(-500px)',
      opacity: 0,
      width: 0,
    },
    headerStatic: {
      background: 'rgba(0,0,0, 0.3)',
      zIndex: 99,
      // boxShadow: 'none'
    },
    toolbarLang: {
      textAlign: 'right',
      color: '#fff',
      paddingTop: 5,
    },
    buttonLang: {
      color: '#fff',
      borderColor: '#fff',
      textTransform: 'capitalize',
      lineHeight: 'normal',
      backgroundColor: 'rgba(255,255,255, 0.4)',
      '&:hover': {
        borderColor: 'rgba(255,255,255, 0.4)',
      },
    },
    alert: {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacing' does not exist on type 'Default... Remove this comment to see the full error message
      marginTop: theme.spacing(1),
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacing' does not exist on type 'Default... Remove this comment to see the full error message
      marginBottom: theme.spacing(1),
    },
  }),
  { name: 'HeaderApp' }
);

let didScroll = false;
let interval = false;
let lastScrollTop = 0;
let navbarHeight = 0;
const timeout = false;

const HeaderApp = memo((props) => {
  const {
    // logout = () => { },
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'isOnline' does not exist on type '{ chil... Remove this comment to see the full error message
    isOnline = true,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'isOnlineLoading' does not exist on type ... Remove this comment to see the full error message
    isOnlineLoading = false,
    // position = 'static',
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'user' does not exist on type '{ children... Remove this comment to see the full error message
    user = null,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'separatorMainLogo' does not exist on typ... Remove this comment to see the full error message
    separatorMainLogo = false,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'isOnlineAction' does not exist on type '... Remove this comment to see the full error message
    isOnlineAction,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'handleChangeLocale' does not exist on ty... Remove this comment to see the full error message
    handleChangeLocale,
  } = props;

  const location = useLocation();

  const { search, pathname } = location;
  const lastPart = pathname.split('/').slice(-1)[0];
  const isDocs = lastPart === 'docs';

  const intl = useIntl();
  const classes = useStyles();
  const navbar = useRef();
  const [state, setState] = useState({
    openSearch: false,
    drawer: {
      top: false,
      left: false,
      bottom: false,
      right: false,
    },
    wordsSelected: 0,
    anchorEl: null,
    anchorElPublication: null,
    scrollUp: false,
    navbarBorder: false,
    position: 'fixed',
    disableHidden: false,
    demoMode: false,
    menuOpen: false,
    openLangMenu: false,
  });

  const handleScroll = () => {
    didScroll = true;
  };

  const [anchorElLang, setAnchorElLang] = useState(null);

  const accountByDomain = useAppSelector((rstate) => rstate.accounts.result.accountByDomain?.[0]);

  const handleClickButtonLang = (event: any) => {
    setAnchorElLang(event.currentTarget);
  };

  const handleCloseLang = () => {
    setAnchorElLang(null);
  };

  const hasScrolled = () => {
    const { scrollUp } = state;
    const st = getDocScroll().top;

    if (!scrollUp) {
      if (st < navbarHeight) {
        setState((prev) => ({
          ...prev,
          scrollUp: true,
          st,
          navbarBorder: false,
        }));
      }
    }
    // Make sure they scroll more than delta
    if (Math.abs(lastScrollTop - st) <= delta) {
      return;
    }
    // If they scrolled down and are past the navbar, add class .nav-up.
    // This is necessary so you never see what is "behind" the navbar.
    if (st > lastScrollTop && st > navbarHeight) {
      // Scroll Down
      setState((prev) => ({ ...prev, scrollUp: false, st }));
      // navbar.current.classList.remove(classes.navDown);
      // navbar.current.classList.add(classes.navUp);
    } else if (st + window.innerHeight < document.body.clientHeight) {
      // Scroll Up
      setState((prev) => ({
        ...prev,
        scrollUp: true,
        navbarBorder: st > navbarHeight,
        st,
      }));
      // navbar.current.classList.remove(classes.navUp);
      // navbar.current.classList.add(classes.navDown);
    }

    lastScrollTop = st;
  };

  useEffect(() => {
    didScroll = true;
    if (navbar.current) {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      navbarHeight = navbar.current.clientHeight;
      window.addEventListener('scroll', handleScroll);
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'Timer' is not assignable to type 'boolean'.
      interval = setInterval(() => {
        if (didScroll) {
          hasScrolled();
          didScroll = false;
        }
      }, 50);
    }

    return () => {
      // unmount
      if (timeout) {
        clearTimeout(timeout);
      }
      if (interval) {
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        clearInterval(interval);
      }
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'true' is not assignable to type 'undefined'.
      mounted.current = true;
    } else {
      window.removeEventListener('scroll', handleScroll);
      window.addEventListener('scroll', handleScroll);
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'Timer' is not assignable to type 'boolean'.
      interval = setInterval(() => {
        if (didScroll) {
          hasScrolled();
          didScroll = false;
        }
      }, 50);
    }
  });

  // const {
  //   scrollUp, navbarBorder, openSearch, st
  // } = state;

  // const headerClassname = classNames('_headerWrapGlobal', classes.headerWrap, {
  //   [classes.navUp]: scrollUp && st < navbarHeight + 100,
  //   [classes.navDown]: !scrollUp && st > navbarHeight,
  //   [classes.headerBorder]: navbarBorder
  // });

  const query = qs.parse(search, { ignoreQueryPrefix: true });

  return (
    <>
      <div className={classes.wrapperIsOnline}>
        <Alert
          variant="filled"
          severity="warning"
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          In observance of Labor Day, our offices will be closed on Monday, September 2nd, 2024.
          Thank you.
        </Alert>
      </div>
      {!isOnline && (
        <div className={classes.wrapperIsOnline}>
          <div className={classes.containerIsOnline}>
            {isOnlineLoading ? 'Restablishing connection' : 'Your connection is down'}
          </div>
          <div className={classes.wrapperConn}>
            <Button
              disabled={isOnlineLoading}
              className={classes.buttonDownConn}
              onClick={() => {
                isOnlineAction();
              }}
              variant="outlined"
              size="small"
              color="primary"
            >
              {isOnlineLoading ? 'Reconnecting...' : 'Reconnect'}
            </Button>
            {isOnlineLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </div>
      )}
      <ElevationScroll>
        <AppBar
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          ref={navbar}
          className={classes.headerStatic}
          color="inherit"
          position={isDocs ? 'absolute' : 'fixed'}
        >
          <div className={classes.toolbarTransparent}>
            <Container size="publicationSize">
              {/* <div className={classes.toolbarLang}> */}
              {/*  <Button */}
              {/*    size="small" */}
              {/*    aria-controls="language-menu" */}
              {/*    aria-haspopup="true" */}
              {/*    onClick={handleClickButtonLang} */}
              {/*    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
              {/*    startIcon={<ReactCountryFlag countryCode={langsToIsoCode[intl.locale]} />} */}
              {/*    endIcon={<ExpandMore />} */}
              {/*    className={classes.buttonLang} */}
              {/*    variant="outlined" */}
              {/*  > */}
              {/*    {langsToText[intl.locale]} */}
              {/*  </Button> */}
              {/*  <Menu */}
              {/*    className={classes.menuLang} */}
              {/*    id="language-menu" */}
              {/*    anchorEl={anchorElLang} */}
              {/*    keepMounted */}
              {/*    open={Boolean(anchorElLang)} */}
              {/*    onClose={handleCloseLang} */}
              {/*    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element[]; className: string; id... Remove this comment to see the full error message */}
              {/*    getContentAnchorEl={null} */}
              {/*    anchorOrigin={{ */}
              {/*      vertical: 'bottom', */}
              {/*      horizontal: 'left', */}
              {/*    }} */}
              {/*    transformOrigin={{ */}
              {/*      vertical: 'top', */}
              {/*      horizontal: 'left', */}
              {/*    }} */}
              {/*  > */}
              {/*    <MenuItem */}
              {/*      onClick={() => { */}
              {/*        handleChangeLocale('en'); */}
              {/*        handleCloseLang(); */}
              {/*      }} */}
              {/*    > */}
              {/*      <ReactCountryFlag className={classes.iconFlag} countryCode="US" /> */}
              {/*      English */}
              {/*    </MenuItem> */}
              {/*    <MenuItem */}
              {/*      onClick={() => { */}
              {/*        handleChangeLocale('es'); */}
              {/*        handleCloseLang(); */}
              {/*      }} */}
              {/*    > */}
              {/*      <ReactCountryFlag className={classes.iconFlag} countryCode="ES" /> */}
              {/*      Spanish */}
              {/*    </MenuItem> */}
              {/*    <MenuItem */}
              {/*      onClick={() => { */}
              {/*        handleChangeLocale('pt'); */}
              {/*        handleCloseLang(); */}
              {/*      }} */}
              {/*    > */}
              {/*      <ReactCountryFlag className={classes.iconFlag} countryCode="PT" /> */}
              {/*      Portuguese */}
              {/*    </MenuItem> */}
              {/*  </Menu> */}
              {/* </div> */}
              <Toolbar className={classes.toolbar}>
                <Typography component="div" color="inherit">
                  <Link className={classes.title} to="/">
                    <img
                      alt="Alt"
                      src={accountByDomain?.themeLogo || LogoSymbolWhite}
                      className={classes.logoHeader}
                    />
                  </Link>
                </Typography>
                {!!separatorMainLogo && (
                  <div className={classes.separatorMainLogoContainer}>
                    <span className={classes.separatorMainLogo} />
                  </div>
                )}
                <Button
                  sx={{ display: { xs: 'none', md: 'inline-flex' } }}
                  className={classes.menuButton}
                  component={Link}
                  to="/login"
                >
                  {intl.formatMessage({
                    id: 'StartShipping',
                    defaultMessage: 'Start Shipping',
                  })}
                </Button>

                <Button
                  sx={{ display: { xs: 'none', md: 'inline-flex' } }}
                  className={classes.menuButton}
                  component={Link}
                  to="/tracking"
                >
                  {intl.formatMessage({
                    id: 'Tracking',
                    defaultMessage: 'Tracking',
                  })}
                </Button>
                <Button
                  sx={{ display: { xs: 'none', md: 'inline-flex' } }}
                  className={classes.menuButton}
                  component={Link}
                  to="/docs"
                >
                  {intl.formatMessage({
                    id: 'API',
                    defaultMessage: 'API',
                  })}
                </Button>
                <Button
                  sx={{ display: { xs: 'none', md: 'inline-flex' } }}
                  className={classes.menuButton}
                  component={Link}
                  to="/contact-us"
                >
                  {intl.formatMessage({
                    id: 'contactUs',
                    defaultMessage: 'Contact Us',
                  })}
                </Button>
                <div className={classes.grow} />
                <div className={classNames(classes.leftDivSearch)}>
                  {!user && (
                    <Button
                      startIcon={<LoginIcon />}
                      className={classes.menuButton}
                      component={Link}
                      to={`/login${query.redirect ? `?redirect=${query.redirect}` : ''}`}
                    >
                      <FormattedMessage id="sign_in" defaultMessage="Sign in" />
                    </Button>
                  )}
                  {user ? (
                    <Button className={classes.menuButton} component={Link} to="/admin/dashboard">
                      {intl.formatMessage({
                        id: 'ServicePanel',
                        defaultMessage: 'Service Panel',
                      })}
                    </Button>
                  ) : (
                    null && (
                      <Button
                        startIcon={<AccountIcon />}
                        className={classes.menuButton}
                        component={Link}
                        to="/register"
                      >
                        {intl.formatMessage({
                          id: 'NewAccount',
                          defaultMessage: 'New Account',
                        })}
                      </Button>
                    )
                  )}
                </div>
              </Toolbar>
            </Container>
          </div>
        </AppBar>
      </ElevationScroll>
    </>
  );
});

// @ts-expect-error ts-migrate(2339) FIXME: Property 'propTypes' does not exist on type 'Named... Remove this comment to see the full error message
HeaderApp.propTypes = {
  separatorMainLogo: PropTypes.bool,
  user: PropTypes.oneOfType([PropTypes.bool, PropTypes.objectOf(PropTypes.any)]),
  // logout: PropTypes.func,
  isOnlineLoading: PropTypes.bool,
  // position: PropTypes.string,
  isOnline: PropTypes.bool,
  isOnlineAction: PropTypes.func.isRequired,
  handleChangeLocale: PropTypes.func.isRequired,
};

export default HeaderApp;
